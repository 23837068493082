import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Button, Table, Input, Row, Col, Checkbox, Popconfirm, Typography, Tooltip, Divider } from 'antd';
import { SearchOutlined, InfoCircleOutlined } from '@ant-design/icons';
import AttributeService from '../../services/AttributeService';
import ProposalService from '../../services/ProposalService';
import ToolService from '../../services/ToolService';
import { formatToTL, openNotification } from '../../utils';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';

const { Text } = Typography;

const Parametres = ({ companyId, addressId, attributes, isEdit = false, authorizedPersonIds }: { companyId: any, addressId: any, attributes: any, isEdit: boolean, authorizedPersonIds: any }) => {
    const [searchText, setSearchText] = useState('');
    const [data, setData] = useState<any[]>([]);
    const [tools, setTools] = useState<any[]>([]);
    const [selectedRows, setSelectedRows] = useState<any[]>([]);
    const [showSelected, setShowSelected] = useState(false);
    const [totalAmount, setTotalAmount] = useState(0);
    const [kdv, setKdv] = useState(0);
    const [totalWithKDV, setTotalWithKDV] = useState(0);
    const [revenueShare, setRevenueShare] = useState(0);
    const location = useLocation();
    const navigate = useNavigate();
    const [offeringLogs, setOfferingLogs] = useState([]);
    const [roles, setRoles] = useState<any>();

    const getQueryParams = (search: string) => {
        return new URLSearchParams(search);
    };

    const queryParams = getQueryParams(location.search);

    useEffect(() => {
        // Fetch tool data
        ToolService.getAllTools()
            .then((response) => {
                setTools(response.data);
            })
            .catch((error) => {
                console.error('Tool data fetch error:', error);
            });

        // Fetch attribute data
        AttributeService.getAll()
            .then((response) => {
                // Filter only active attributes
                const allAttributes = response.data
                    .filter((item: any) => item.isActive) // Only include attributes where isActive is true
                    .sort((a: any, b: any) => {
                        if (a.orderNo == null && b.orderNo == null) {
                            return 0;
                        } else if (a.orderNo == null) {
                            return 1;
                        } else if (b.orderNo == null) {
                            return -1;
                        } else {
                            return a.orderNo - b.orderNo;
                        }
                    })
                    .map((item: any) => ({
                        ...item,
                        quantity: null,
                        period: 1,
                    }));

                let initialSelectedRows: any[] = [];
                if (attributes && attributes.length > 0) {
                    const enrichedAttributes = allAttributes.map((attribute: any) => {
                        const matchedAttribute = attributes.find((attr: any) => attr.attributeId === attribute.id);
                        if (matchedAttribute) {
                            const enrichedAttribute = {
                                ...attribute,
                                quantity: matchedAttribute.quantity,
                                period: matchedAttribute.period,
                                price: matchedAttribute.price,
                                fiyat: matchedAttribute.price * matchedAttribute.quantity
                            };
                            initialSelectedRows.push({
                                parametreId: enrichedAttribute.id,
                                birimFiyat: enrichedAttribute.price,
                                quantity: enrichedAttribute.quantity,
                                period: enrichedAttribute.period,
                                fiyat: enrichedAttribute.price * enrichedAttribute.quantity
                            });
                            return enrichedAttribute;
                        }
                        return attribute;
                    });

                    setData(enrichedAttributes);
                    setSelectedRows(initialSelectedRows);

                } else {
                    setData(allAttributes);
                }
            })
            .catch((error) => {
                console.error("Attributes data fetch error:", error);
            });

    }, [attributes]);


    const handleCalculatePrice = () => {
        const uniqueSelectedRows = selectedRows.filter(
            (row, index, self) => index === self.findIndex((r) => r.parametreId === row.parametreId)
        );
    
        const payload = {
            addressId,
            companyId,
            authorizedPersonIds,
            attributes: uniqueSelectedRows
                .filter((row) => row.quantity > 0)
                .map((row) => ({
                    attributeId: row.parametreId,
                    price: row.birimFiyat,
                    quantity: row.quantity,
                    period: row.period,
                })),
        };
    
        ProposalService.calculatePrice(payload)
            .then((response) => {
                const { totalPrice, kdv, totalWithKDV, revenueShare, offeringLogs } = response.data;
                setTotalAmount(totalPrice);
                setKdv(kdv);
                setTotalWithKDV(totalWithKDV);
                setRevenueShare(revenueShare);
                setOfferingLogs(offeringLogs);
            })
            .catch((error) => {
                openNotification('error', 'Hata', 'Fiyat hesaplanırken bir hata oluştu.');
            });
    };

    useEffect(() => {
        handleCalculatePrice();
    }, [selectedRows])

    useEffect(() => {
        let storedRoles: any = localStorage.getItem('roles');
        let parsedRoles = JSON.parse(storedRoles);
        setRoles(parsedRoles);
    }, [])

    const handleRowSelection = (record: any) => {
        const isSelected = isRowSelected(record);

        let newSelectedRows = [...selectedRows];

        if (isSelected) {
            // Seçili durumdaysa, yalnızca o satırı kaldır
            newSelectedRows = newSelectedRows.filter((row) => row.parametreId !== record.id);
        } else {
            // Seçili değilse, yeni satırı ekle
            newSelectedRows.push({
                parametreId: record.id,
                birimFiyat: record.price,
                quantity: record.quantity !== undefined ? record.quantity : 0,
                period: record.period !== undefined ? record.period : 1,
                fiyat: record.price * (record.quantity !== undefined ? record.quantity : 0),
            });

            // Eğer grup elemanıysa, grubun diğer elemanlarını da seç
            const group = groupedData.find((g) =>
                g.children?.some((child: any) => child.id === record.id)
            );
            if (group) {
                group.children.forEach((child: any) => {
                    if (!isRowSelected(child)) {
                        newSelectedRows.push({
                            parametreId: child.id,
                            birimFiyat: child.price,
                            quantity: child.quantity !== undefined ? child.quantity : 0,
                            period: child.period !== undefined ? child.period : 1,
                            fiyat: child.price * (child.quantity !== undefined ? child.quantity : 0),
                        });
                    }
                });
            }
        }

        setSelectedRows(newSelectedRows);
    };


    const isRowSelected = (record: any) => {
        return selectedRows.some((row) => row.parametreId === record.id);
    };

    const handleInputChange = (value: number | null, record: any, field: 'quantity' | 'period') => {
        const updatedData = data.map((item) => {
            if (item.id === record.id) {
                return {
                    ...item,
                    [field]: value,
                    fiyat: item.price * (field === 'quantity' ? (value ?? 0) : (item.quantity ?? 0)),
                };
            }
            return item;
        });

        const updatedSelectedRows = [...selectedRows];
        const rowIndex = updatedSelectedRows.findIndex((row) => row.parametreId === record.id);

        if (rowIndex === -1) {
            // Eleman seçili değilse, seçili hale getiriyoruz
            updatedSelectedRows.push({
                parametreId: record.id,
                birimFiyat: record.price,
                quantity: field === 'quantity' ? value : record.quantity,
                period: field === 'period' ? value : record.period,
                fiyat: record.price * (field === 'quantity' ? (value ?? 0) : (record.quantity ?? 0)),
            });
        } else {
            // Eleman zaten seçiliyse, değerleri güncelliyoruz
            updatedSelectedRows[rowIndex][field] = value;
            updatedSelectedRows[rowIndex].fiyat =
                updatedSelectedRows[rowIndex].birimFiyat *
                (updatedSelectedRows[rowIndex].quantity ?? 0);
        }

        // Eğer eleman bir gruba aitse, grubun diğer elemanlarını otomatik seç
        const group = groupedData.find((g) =>
            g.children?.some((child: any) => child.id === record.id)
        );
        if (group) {
            group.children.forEach((child: any) => {
                const childRowIndex = updatedSelectedRows.findIndex(
                    (row) => row.parametreId === child.id
                );
                if (childRowIndex === -1) {
                    updatedSelectedRows.push({
                        parametreId: child.id,
                        birimFiyat: child.price,
                        quantity: child.quantity ?? 0,
                        period: child.period ?? 1,
                        fiyat: child.price * (child.quantity ?? 0),
                    });
                }
            });
        }

        setData(updatedData);
        setSelectedRows(updatedSelectedRows);
    };


    const handleTeklifOlustur = () => {
        const hasErrors = selectedRows.some((row) =>
            row.isGroup === false && (
                row.quantity === null ||
                row.quantity === undefined
            )
        );

        selectedRows.some((row) =>
            console.log(row.quantity)

        );
        if (hasErrors) {
            openNotification('error', 'Hata', 'Lütfen tüm seçili parametreler için geçerli bir adet girin.');
            return;
        }

        if (addressId === null) {
            openNotification('error', 'Hata', 'Lütfen adres seçiniz.');
            return;
        }
        if (addressId === null) {
            openNotification('error', 'Hata', 'Lütfen adres seçiniz.');
            return false
        }
        if (!authorizedPersonIds || authorizedPersonIds.length === 0) {
            openNotification('error', 'Hata', 'Lütfen yetkili seçiniz.');
            return false
        }
        const payload = {
            addressId: addressId,
            companyId: companyId,
            authorizedPersonIds: authorizedPersonIds,
            incrementRevision: true,
            attributes: selectedRows
                .filter(row => row.quantity > 0)
                .map(row => ({
                    attributeId: row.parametreId,
                    price: row.birimFiyat,
                    quantity: row.quantity,
                    period: row.period,
                }))
        };

        if (attributes) {
            const proposalIdFromQuery: any = queryParams.get('proposalId');

            ProposalService.update(proposalIdFromQuery, payload)
                .then(response => {
                    openNotification('success', 'Başarılı', 'Teklif başarıyla güncellendi.');
                    setTimeout(() => {
                        navigate('/proposals', { replace: true });
                        window.location.reload();
                    }, 1200);

                })
                .catch(error => {
                    openNotification('error', 'Hata', 'Teklif oluşturulurken bir hata oluştu.');
                });
        } else {
            ProposalService.create(payload)
                .then(response => {
                    openNotification('success', 'Başarılı', 'Teklif başarıyla oluşturuldu.');
                    setTimeout(() => {
                        navigate('/proposals', { replace: true });
                        window.location.reload();
                    }, 1000);
                })
                .catch(error => {
                    openNotification('error', 'Hata', 'Teklif oluşturulurken bir hata oluştu.');
                });
        }
    };

    const columns = [
        {
            title: 'Adı',
            dataIndex: 'name',
            key: 'name',
            render: (text: any, record: any) => {
                const isGroup = record.isGroup;

                return (
                    <div
                        style={{
                            display: 'flex',
                            alignItems: isGroup ? 'flex-start' : 'flex-start', // Grup ve normal için hizalama
                            gap: '8px',
                        }}
                    >
                        <Checkbox
                            checked={isGroup ? isGroupSelected(record) : isRowSelected(record)}
                            indeterminate={isGroup && isGroupIndeterminate(record)}
                            onChange={() =>
                                isGroup
                                    ? handleGroupCheckboxChange(record)
                                    : handleRowSelection(record)
                            }
                            onClick={(e) => e.stopPropagation()}
                            style={{
                                display: 'flex',

                                marginTop: isGroup ? '0px' : '0px',
                                alignItems: isGroup ? 'top' : 'top', // Grup ve normal için hizalama
                                // Normal satırda hafif yukarı kaydırma
                            }}
                            disabled={!isGroup && isEdit}
                        />
                        <span style={{ fontWeight: isGroup ? 'bold' : 'normal' }}>{text}</span>
                    </div>
                );
            },
        },
        {
            title: 'Açıklama',
            dataIndex: 'description',
            key: 'description',
            render: (text: string) => {
                return <span>{text}</span>
            },
        },
        {
            title: 'Adet',
            key: 'quantity',
            width: 150,
            render: (text: string, record: any) => record.isGroup ? null : (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Input
                        value={record.quantity !== null && record.quantity !== undefined ? record.quantity : ''}
                        type="number"
                        className="table-input"
                        disabled={isEdit}
                        min={0}
                        style={{ width: '80px' }}
                        onClick={(e) => e.stopPropagation()}
                        onChange={(e) => {
                            const value = parseInt(e.target.value);
                            handleInputChange(isNaN(value) ? null : value, record, 'quantity');
                        }}
                    />
                    {
                        isRowSelected(record) && (record.quantity === null || record.quantity === undefined || isNaN(record.quantity)) && (
                            <Text type="danger" style={{ fontSize: '12px', marginTop: '5px' }}>
                                Bir değer giriniz
                            </Text>
                        )
                    }
                </div>
            ),
        },
        {
            title: 'Periyot (Yılda)',
            key: 'period',
            render: (text: string, record: any) => record.isGroup ? null : (
                <Input
                    value={record.period}
                    type="number"
                    className='table-input'
                    disabled={isEdit}
                    min={1}
                    defaultValue={1}
                    style={{ width: '80px' }}
                    onClick={(e) => e.stopPropagation()}
                    onChange={(e) => handleInputChange(parseInt(e.target.value), record, 'period')}
                />

            ),
        },
    ];

    const groupedData: any[] = [];

    data.forEach((item: any) => {
        if (!item.toolId) {
            // Items without a group
            groupedData.push({ ...item, key: item.id, isGroup: false });
        } else {
            // Grouped items
            const tool = tools.find((t: any) => t.id === item.toolId);
            const toolName = tool ? tool.name : 'Diğer';
            const toolDesc = tool ? tool.description : 'Diğer';
            let group = groupedData.find((g: any) => g.toolId === item.toolId && g.isGroup);
            if (!group) {
                group = {
                    key: 'group-' + item.toolId,
                    name: toolName,
                    isGroup: true,
                    description: toolDesc,
                    toolId: item.toolId,
                    children: [],
                };
                groupedData.push(group);
            }
            group.children.push({ ...item, key: item.id, isGroup: false });
        }
    });

    // Flatten groups and ungrouped items for rendering
    const treeData = groupedData
        .map((group) => {
            if (group.isGroup) {
                // Filter children inside the group
                const filteredChildren = group.children.filter((child: any) => {
                    const searchLower = searchText.toLocaleLowerCase('tr');
                    return (
                        (child?.name?.toLocaleLowerCase('tr').includes(searchLower) || '') ||
                        (child?.description?.toLocaleLowerCase('tr').includes(searchLower) || '')
                    );
                });
                return { ...group, children: filteredChildren };
            }
            // Standalone items (toolId null)
            const searchLower = searchText.toLocaleLowerCase('tr');
            return (
                (group?.name?.toLocaleLowerCase('tr').includes(searchLower) || '') ||
                (group?.description?.toLocaleLowerCase('tr').includes(searchLower) || '')
            ) ? group : null;
        })
        .filter((group) => {
            // Exclude empty groups and null items
            if (group?.isGroup) {
                return group.children && group.children.length > 0;
            }
            return group !== null;
        })
        .sort((a: any, b: any) => {
            if (a.orderNo == null && b.orderNo == null) {
                return 0;
            } else if (a.orderNo == null) {
                return 1;
            } else if (b.orderNo == null) {
                return -1;
            } else {
                return a.orderNo - b.orderNo;
            }
        });

    const handleGroupCheckboxChange = (group: any) => {
        const groupItemIds = group.children.map((item: any) => item.id);
        const isAllSelected = groupItemIds.every((id: any) => selectedRows.some(row => row.parametreId === id));

        let newSelectedRows = [...selectedRows];

        if (isAllSelected) {
            // Grubu kaldırma
            newSelectedRows = newSelectedRows.filter(row => !groupItemIds.includes(row.parametreId));
        } else {
            // Grubu ekleme ve adetleri null yapma
            const itemsToAdd = group.children.map((item: any) => ({
                parametreId: item.id,
                birimFiyat: item.price,
                quantity: null, // Adetleri null yapıyoruz
                period: item.period || 1,
                fiyat: item.price * 0,
            }));
            newSelectedRows = [...newSelectedRows, ...itemsToAdd];
        }

        setSelectedRows(newSelectedRows);
    };


    const isGroupSelected = (group: any) => {
        const groupItemIds = group.children.map((item: any) => item.id);
        return groupItemIds.every((id: any) => selectedRows.some((row) => row.parametreId === id));
    };

    const isGroupIndeterminate = (group: any) => {
        const groupItemIds = group.children.map((item: any) => item.id);
        const selectedCount = groupItemIds.filter((id: any) =>
            selectedRows.some((row) => row.parametreId === id)
        ).length;
        return selectedCount > 0 && !isGroupSelected(group);
    };


    const generateOfferingLogsTooltip = () => {
        return (
            <div style={{
                padding: '10px',
                borderRadius: '8px',
                color: '#fff',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',  // Hafif bir gölge ekliyoruz
                fontWeight: 'bold',
                maxHeight: '300px',  // Maksimum yüksekliği belirtiyoruz
                overflowY: 'auto',
                width: '350px' // Scrollbar ekliyoruz
            }}>
                {offeringLogs?.map((offering: any, index) => (
                    <div key={index} style={{ marginBottom: '15px' }}>
                        <strong style={{ fontSize: '16px' }}>{offering.offeringName}</strong>
                        <ul style={{ paddingLeft: '20px', marginTop: '10px' }}>
                            {offering.attributes.map((attr: any, idx: any) => (
                                <li key={idx} style={{ fontSize: '14px', marginBottom: '5px' }}>
                                    {attr.attributeName} - <span style={{ fontWeight: 'normal' }}>Adet:</span> {attr.quantity}
                                </li>
                            ))}
                        </ul>
                        <div style={{ marginTop: '10px', fontSize: '14px' }}>
                            {offering.serviceFeeDifference > 0 && (
                                <Text style={{ color: '#fff' }}>
                                    Servis Fiyatı: {formatToTL(offering.serviceFeeDifference)}
                                </Text>
                            )}<br />
                            <Text style={{ color: '#fff' }}>Son Fiyat: {formatToTL(offering.finalPrice)}</Text>
                        </div>
                        {/* Divider ile hizmetler arasında geçiş sağlıyoruz */}
                        {index !== offeringLogs?.length - 1 && (
                            <Divider style={{ background: 'gray', height: '2px' }} />
                        )}
                    </div>
                ))}
            </div>
        );
    };

    // Seçili verileri orderNo'ya göre sıralıyoruz
    const selectedData = useMemo(() => {
        return selectedRows
            .map((row) => data.find((item) => item.id === row.parametreId))
            .filter(Boolean)
            .sort((a: any, b: any) => {
                if (a.orderNo == null && b.orderNo == null) {
                    return 0;
                } else if (a.orderNo == null) {
                    return 1;
                } else if (b.orderNo == null) {
                    return -1;
                } else {
                    return a.orderNo - b.orderNo;
                }
            });
    }, [selectedRows, data]);

    const expandedRowKeys = useMemo(() => {
        return treeData
            .filter((item) => item.isGroup) // Sadece grupları seç
            .map((item) => item.key);      // Grupların anahtarlarını topla
    }, [treeData]);


    return (
        <>
            <Row justify="space-between" align="top">
                <Col>
                    <h2 className='text-start mt-10px' >Parametre Listesi</h2>
                </Col>
                <Col>
                    <Row align={'middle'} justify={'center'}>
                        <div className='text-right'>
                            <Row >
                                <Button
                                    type={!showSelected ? 'default' : 'primary'}
                                    className="d-button fs-14px weight-500 mr-16px width-auto "
                                    onClick={() => setShowSelected(true)}
                                >
                                    Seçili Parametreler
                                </Button>
                                <Button
                                    type={showSelected ? 'default' : 'primary'}
                                    className="d-button fs-14px weight-500 width-auto"
                                    onClick={() => setShowSelected(false)}
                                >
                                    Tüm Parametreler
                                </Button>

                            </Row>
                        </div>
                        <Input
                            placeholder="Ara"
                            prefix={<SearchOutlined style={{ color: '#b8b9bc' }} />}
                            className="d-input d-input-form ml-16px d-input-filter"
                            style={{ width: 200 }}
                            onChange={(e) => setSearchText(e.target.value)}
                        />
                    </Row>
                    <Row justify={'end'}>
                        <Link to={'/tickets?parametres=help'} className='mt-10px mb-10px '>
                            <Text style={{ fontWeight: 'bold', fontSize: 12, textDecoration: 'underline' }} >Aradığınız ekipman/tesisat bilgisini bulamıyor musunuz?</Text>
                        </Link>
                    </Row>
                </Col>
            </Row>
            <Table
                scroll={{ x: 768 }}
                columns={columns}
                dataSource={showSelected ? selectedData : treeData}
                pagination={false}
                rowKey="key"
                expandable={{
                    expandRowByClick: true,
                    rowExpandable: (record) => record.isGroup, // Sadece gruplar genişletilebilir
                }}
                expandedRowKeys={expandedRowKeys} // Varsayılan olarak açık gruplar
                onRow={(record) => {
                    if (!record.isGroup) {
                        return {
                            onClick: (event) => {
                                event.stopPropagation(); // Tıklama olayının yayılmasını engeller
                                // handleRowSelection(record);
                            },
                        };
                    }
                    return {};
                }}
            />
            <div style={{ textAlign: 'right', marginTop: '20px' }}>
                {offeringLogs?.map((offering: any, index) => (
                    <div key={index} style={{ marginBottom: '15px' }}>
                        <div style={{ marginTop: '10px', fontSize: '14px' }}>
                            {offering.serviceFeeDifference > 0 && (
                                <>
                                    <Text strong>{offering.offeringName} servis fiyatı: {formatToTL(offering.serviceFeeDifference)}</Text><br />
                                </>
                            )}
                        </div>
                    </div>
                ))}

                <Text strong>
                    Toplam Fiyat: {formatToTL(totalAmount)}{' '}
                    <Tooltip title={generateOfferingLogsTooltip()}
                        overlayStyle={{ maxWidth: '450px', overflowY: 'auto', maxHeight: '300px' }}
                    >
                        <InfoCircleOutlined style={{ color: '#1890ff', marginLeft: '8px' }} />
                    </Tooltip>
                </Text><br />

                {roles && !roles.some((role: any) => role.name === 'ADMIN') && (
                    <Text type="success">Potansiyel kazancım: {formatToTL(revenueShare)}</Text>
                )}
                <br />
            </div>
            {!isEdit &&
                <div style={{ marginTop: '20px', textAlign: 'right' }}>
                    <Popconfirm
                        title="Teklifi iptal etmek istediğinize emin misiniz?"
                        onConfirm={() => {
                            navigate('/proposals', { replace: true });
                            window.location.reload();
                        }}
                        okText="Evet"
                        cancelText="Hayır"
                    >
                        <Button type="default" className="d-button fs-16px weight-500 width-auto mr-10px">Teklifi İptal Et</Button>
                    </Popconfirm>
                    <Popconfirm
                        title="Teklif oluşturmak istediğinize emin misiniz?"
                        onConfirm={handleTeklifOlustur}
                        okText="Evet"
                        cancelText="Hayır"
                    >
                        <Button type="primary" className="d-button fs-16px weight-500 width-auto dark-button">Teklif Oluştur</Button>
                    </Popconfirm>
                </div>
            }
        </>
    );
};

export default Parametres;
